@import url(//fonts.googleapis.com/css?family=Lato:300, 400, 700);

body {
  margin: 0;
  font-weight: 300;
  font-family: "Lato", Calibri, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(243, 239, 224);
  color: rgb(72, 167, 112);
  font-size: 21px;
}

body a,
a:visited,
a:link {
  color: rgb(72, 167, 112);
}

h1 {
  margin: 0;
  font-weight: 300;
  font-size: 2em;
  line-height: 1.3;
}

p {
  color: rgba(0, 0, 0, 0.8);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

blockquote {
  border-left: 10px solid rgba(72, 167, 112, 0.8);
  margin: 1.5em 50px;
  padding: 0.5em 10px;
  quotes: "“" "”" "“" "”";
}

blockquote:before {
  color: inherit;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
  font-family: Arial, sans-serif;
}

blockquote p {
  display: inline;
}
